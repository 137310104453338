import {
  Modal,
  dayjs,
  logoutUserFromStore,
  useAppDispatch,
  useAppSelector,
  useDimensions,
  useMessagesHome,
} from "@constituentvoice/cv-elements/web";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faCalendarDay } from "@fortawesome/pro-regular-svg-icons/faCalendarDay";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import { faQuestionSquare } from "@fortawesome/pro-regular-svg-icons/faQuestionSquare";
import { faScroll } from "@fortawesome/pro-regular-svg-icons/faScroll";
import { faCog } from "@fortawesome/pro-solid-svg-icons/faCog";
import { faFileAlt } from "@fortawesome/pro-solid-svg-icons/faFileAlt";
import { faSignOut } from "@fortawesome/pro-solid-svg-icons/faSignOut";
import { faUniversity } from "@fortawesome/pro-solid-svg-icons/faUniversity";
import { faUser } from "@fortawesome/pro-solid-svg-icons/faUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Item } from "@react-stately/collections";
import { useOverlayTriggerState } from "@react-stately/overlays";
import { PropsWithChildren, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { AlertDialog } from "../AlertDialog/AlertDialog";
import { Notifications } from "../Notifications/Notifications";
import { UserMenuButton } from "../UserMenu/UserMenuButton";

import {
  ClientAndUserContainer,
  ClientContainer,
  ClientLogo,
  ClientVendor,
  Content,
  DesktopTextLink,
  Layout,
  MainNavItem,
  Nav,
  NavContainer,
  NavItemIconContainer,
  NavItemText,
  UserMenuItem,
  UserMenuItemIconContainer,
} from "./TopNav.styles";

interface NavListItem {
  icon: IconDefinition;
  name: string;
  path: string;
  hidden?: boolean;
  additionalPaths?: Array<string>;
  ignorePaths?: Array<string>;
}

const useProfileNavListItems = (): NavListItem[] => {
  const token = useAppSelector((state) => state.user.token);
  const currentUser = useAppSelector((state) => state.user.currentUser);
  const { event: currentEvent, organization: client } =
    useAppSelector((state) => state.currentEvent.currentEvent) || {};

  const paths = useMemo(() => {
    if (!token) return [];
    if (!client || !currentUser || !currentEvent)
      return [
        {
          icon: faSignOut,
          name: "Sign Out",
          path: "/",
        },
      ];
    const { serviceCode } = client;
    const eventId = currentEvent?.id;
    return [
      {
        icon: faUser,
        name: "Profile",
        path: `/${serviceCode}/${eventId}/profile/${currentUser?.id}`,
      },
      // {
      //   icon: faCog,
      //   name: "Settings",
      //   path: `/${serviceCode}/${eventId}/settings`,
      // },
      {
        icon: faSignOut,
        name: "Sign Out",
        path: `/${serviceCode}/${eventId}/`,
      },
    ];
  }, [token, client, currentUser, currentEvent]);

  return paths;
};

const useMainNavListItems = (showEventFeedback: boolean): NavListItem[] => {
  const currentEvent = useAppSelector(
    (state) => state.currentEvent.currentEvent,
  );
  const client = currentEvent?.organization;

  const paths = useMemo(() => {
    if (!client || !currentEvent) return [];
    const serviceCode = client.serviceCode;
    const eventId = currentEvent?.event?.id;
    return [
      {
        icon: faCalendarDay,
        name: "Meetings",
        path: `/${serviceCode}/${eventId}/`,
        additionalPaths: [`/${serviceCode}/${eventId}/meeting`],
      },
      {
        icon: faScroll,
        name: "Bills",
        path: `/${serviceCode}/${eventId}/event-bills`,
      },
      {
        icon: faFileAlt,
        name: "Event Feedback",
        path: `/${serviceCode}/${eventId}/event/survey`,
        hidden: !showEventFeedback,
      },
      {
        icon: faUniversity,
        name: "Legislators",
        path: `/${serviceCode}/${eventId}/legislators-home`,
      },
      {
        icon: faQuestionSquare,
        name: "Support",
        path: `/${serviceCode}/${eventId}/user-support`,
      },
      {
        icon: faBars,
        name: "More",
        path: `/${serviceCode}/${eventId}/more`,
        additionalPaths: [
          `/${serviceCode}/${eventId}/event/`, // event documents
          `/${serviceCode}/${eventId}/documents`,
          `/${serviceCode}/${eventId}/directory`,
          `/${serviceCode}/${eventId}/messages`,
          `/${serviceCode}/${eventId}/lobby-day`,
          `/${serviceCode}/${eventId}/settings`,
          `/${serviceCode}/${eventId}/what-to-expect`,
          `/${serviceCode}/${eventId}/profile/`,
        ],
        ignorePaths: [`/${serviceCode}/${eventId}/event/survey`],
      },
    ];
  }, [client, showEventFeedback, currentEvent]);

  return paths;
};

const isActiveItem = (
  currentPath: string,
  itemPath: string,
  additionalItemPaths?: Array<string>,
  ignoreItemPaths?: Array<string>,
  clientServiceCode?: string,
  eventId?: number,
): boolean => {
  if (!Boolean(currentPath)) return false;
  if (ignoreItemPaths?.includes(currentPath)) return false;
  if (currentPath === itemPath) return true;
  if (itemPath !== `/${clientServiceCode}/${eventId}/`) {
    const pathStartsWithItemPath = currentPath.startsWith(itemPath);
    if (pathStartsWithItemPath) return true;
  }
  return (
    additionalItemPaths?.some((path) => currentPath.startsWith(path)) || false
  );
};

const NavComponent = (props: TopNavProps) => {
  const currentUser = useAppSelector((state) => state.user.currentUser);
  const { event: currentEvent, organization: client } =
    useAppSelector((state) => state.currentEvent.currentEvent) || {};
  const profileNavListItems = useProfileNavListItems();
  const { showEventFeedback } = props;
  const { isDesktop } = useDimensions();

  const mainNavListItems = useMainNavListItems(showEventFeedback);
  const navigate = useNavigate();
  const path = window?.location?.pathname;
  const dispatch = useAppDispatch();

  const navListItems = !isDesktop
    ? [...mainNavListItems, ...profileNavListItems]
    : profileNavListItems;

  const {
    markAllNotificationsAsRead,
    markNotificationAsRead,
    selectedNotification,
    notifications,
    setSelectedNotification,
  } = useMessagesHome();

  const notificationAlertState = useOverlayTriggerState({});

  const onMenuButtonPress = (menuItemName: any) => {
    if (menuItemName === "Sign Out") {
      void dispatch(logoutUserFromStore());
      window.location.reload();
    }
    const menuItem = navListItems.find((item) => item.name === menuItemName);
    if (client && menuItem) {
      navigate(menuItem.path);
    } else {
      console.log({ menuItem, menuItemName });
    }
  };

  useEffect(() => {
    notificationAlertState.setOpen(Boolean(selectedNotification));
  }, [selectedNotification, notificationAlertState]);

  const unreadCount = notifications?.filter((notif) => !notif.isRead).length;

  return (
    <>
      <ClientAndUserContainer>
        {client && Boolean(currentUser) && (
          <ClientContainer>
            <ClientLogo
              src={
                currentEvent?.logoUrl || client.logoUrl || "/images/capitol.png"
              }
              alt={client.name}
            />
            <ClientVendor>{client.name}</ClientVendor>
          </ClientContainer>
        )}
        {Boolean(client) && Boolean(currentUser) && (
          <Notifications
            unreadCount={unreadCount}
            notifications={notifications}
            notificationsPageUrl={`/${client?.serviceCode}/messages/`}
            onMarkAllReadClicked={markAllNotificationsAsRead}
            setSelectedNotification={setSelectedNotification}
          />
        )}
        {selectedNotification && notificationAlertState.isOpen ? (
          <Modal state={notificationAlertState}>
            <AlertDialog
              title={selectedNotification.title}
              date={dayjs(selectedNotification.created).format(
                "hh:mm A MMMM DD, YYYY",
              )}
              confirmLabel={"Dismiss"}
              onConfirm={() => {
                void markNotificationAsRead?.(selectedNotification.id);
                setSelectedNotification(null);
              }}
            >
              {selectedNotification.body}
            </AlertDialog>
          </Modal>
        ) : null}
        {navListItems.length > 0 && (
          <UserMenuButton
            onAction={(e) => onMenuButtonPress(e)}
            label={
              currentUser
                ? `${currentUser.firstName} ${currentUser.lastName}`
                : "Menu"
            }
          >
            {navListItems.map((item) => (
              <Item key={item.name} aria-label={item.name}>
                <UserMenuItem>
                  <UserMenuItemIconContainer>
                    <FontAwesomeIcon icon={item.icon} />
                  </UserMenuItemIconContainer>
                  <span>{item.name}</span>
                </UserMenuItem>
              </Item>
            ))}
          </UserMenuButton>
        )}
      </ClientAndUserContainer>
      <Nav>
        {Boolean(currentUser) && isDesktop && (
          <NavContainer>
            {mainNavListItems
              .filter((item) => !item?.hidden)
              .map((item) => (
                <MainNavItem
                  key={item.name}
                  active={isActiveItem(
                    path,
                    item.path,
                    item.additionalPaths,
                    item.ignorePaths,
                    client?.serviceCode,
                    currentEvent?.id,
                  )}
                >
                  <DesktopTextLink
                    to={item.path}
                    aria-current={path === item.path ? "page" : undefined}
                  >
                    <NavItemIconContainer>
                      <FontAwesomeIcon icon={item.icon} />
                    </NavItemIconContainer>
                    <NavItemText>{item.name}</NavItemText>
                    <NavItemIconContainer>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </NavItemIconContainer>
                  </DesktopTextLink>
                </MainNavItem>
              ))}
          </NavContainer>
        )}
      </Nav>
    </>
  );
};

interface TopNavProps extends PropsWithChildren {
  showEventFeedback: boolean;
}

export const TopNav = (props: TopNavProps) => {
  const currentUser = useAppSelector((state) => state.user.currentUser);
  const { children, showEventFeedback } = props;

  return (
    <Layout shouldShowSidebar={Boolean(currentUser)}>
      <NavComponent showEventFeedback={showEventFeedback} />
      <Content>{children}</Content>
    </Layout>
  );
};
