import {
  ChooseYourGroupUI,
  setCurrentUser,
  useAppDispatch,
  useChooseYourGroup,
  useTheme,
} from "@constituentvoice/cv-elements/web";
import { useEffect, useLayoutEffect } from "react";
import { ActivityIndicator } from "react-native-paper";
import { useNavigate } from "react-router-dom";

import { useAppTitle } from "../../hooks/use-client-title";

export const ChooseYourGroupView = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  useAppTitle("Choose Your Group");

  useLayoutEffect(() => {
    dispatch(setCurrentUser());
  }, [dispatch]);

  const { upcomingEvents, isLoading, previousEvents, storeClientOnPress } =
    useChooseYourGroup();

  useEffect(() => {
    const allEvents = [...upcomingEvents, ...previousEvents];
    if (allEvents.length === 1) {
      storeClientOnPress(allEvents[0]);
      navigate(`/${allEvents[0].organization.serviceCode}`);
    }
  }, [upcomingEvents, previousEvents, navigate, storeClientOnPress]);

  if (isLoading)
    return <ActivityIndicator size={"large"} color={theme.colors.accent} />;

  return (
    <ChooseYourGroupUI
      upcomingEvents={upcomingEvents}
      previousEvents={previousEvents}
      defaultLogoURL={"/images/capitol.png"}
      onSelectEvent={(event) => {
        if (event) {
          navigate(`/${event.organization.serviceCode}/${event.id}`);
        }
      }}
      onSelectOrganization={(event) => {
        if (event) {
          navigate(`/${event.organization.serviceCode}/${event.id}`);
        }
      }}
    />
  );
};
