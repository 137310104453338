import { CVButton, CVTouchable } from "@constituentvoice/cv-elements/web";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css } from "styled-components";

import { Popover } from "../aria/Popover";

export const NotificationsTriggerButton = styled(CVButton).attrs({
  variant: "accent",
})`
  padding: 0;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
  gap: 0.25rem;
`;

export const NotificationsLayeredIcons = styled.div`
  position: relative;
  display: flex;
  padding: 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  width: 3rem;
  height: 3rem;
`;

export const NotificationIcon = styled(FontAwesomeIcon)`
  color: inherit;
  text-align: center;
  width: 1.25rem;
  height: 1.25rem;
`;

export const NotificationsIndicator = styled(FontAwesomeIcon)`
  color: inherit;
  position: absolute;
  top: 0%;
  right: 0%;
  transform: translate(-50%, 125%);
  text-align: center;
  width: 0.5rem;
  height: 0.5rem;
`;

export const NotificationsButtonText = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  ${(p) => p.theme.breakpoints.from(p.theme.breakpoints.tabletSmall)} {
    position: relative;
    width: auto;
    height: auto;
    padding-right: 1rem;
    ${(p) => p.theme.typography.label.large};
  }
`;

export const NotificationsValue = styled(NotificationsButtonText)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(p) => p.theme.colors.onAccent};
  color: ${(p) => p.theme.colors.accent};
  border-radius: 0.25rem;
  padding: 0.125rem 0.25rem;
  min-width: 1.5rem;
  height: 1.5rem;
  font-weight: 600;
`;

export const NotificationsPopover = styled(Popover)`
  border-radius: 0.25rem;
  box-shadow:
    0px 2.75px 9px 0px rgba(0, 0, 0, 0.19),
    0px 0.25px 3px 0px rgba(0, 0, 0, 0.04);
  width: 80%;
  max-width: 23.75rem;
  overflow: hidden;

  & > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 20rem;
    overflow-y: auto;

    & > li:not(:last-of-type) {
      border-bottom: 0.0625rem solid ${(p) => p.theme.colors.outlineVariant};
    }
  }
`;

export const NotificationsPopoverHeader = styled(CVButton)`
  padding: 0;
  background-color: ${(p) => p.theme.colors.white};
  border: none;
  width: 100%;
  text-align: start;
  justify-content: flex-start;
  gap: 0.25rem;
  border-radius: 0;
  border-bottom: 0.0625rem solid ${(p) => p.theme.colors.outlineVariant};
  ${(p) => p.theme.typography.label.large};

  & > span {
    flex: 1;
  }
`;

export const NotificationsPopoverHeaderIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  width: 3rem;
  height: 3rem;
`;

export const NotificationItem = styled(CVTouchable).attrs({ as: "a" })<{
  isUnread?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 1rem;
  gap: 0.75rem;
  text-decoration: none;
  color: ${(p) => p.theme.colors.onPrimary};
  background: ${(p) => (p.isUnread ? p.theme.colors.accentLight : "none")};

  ${(p) =>
    p.isUnread
      ? css`
          & svg {
            color: ${p.theme.colors.accent};
          }
        `
      : ""}
`;

export const NotificationTexts = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0.5rem 0;
  gap: 0.25rem;
`;

export const NotificationTitle = styled.p`
  ${(p) => p.theme.typography.label.medium};
`;

export const NotificationDate = styled.span`
  ${(p) => p.theme.typography.label.small};
`;

export const NotificationsButtonsContainer = styled.div`
  border-top: 0.0625rem solid ${(p) => p.theme.colors.outlineVariant};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
`;

export const ShowAllNotificationsLink = styled(NotificationItem)`
  width: unset;
  align-self: flex-start;
  padding: 0.75rem 1rem;
  border: none;
`;

export const MarkAsReadButton = styled(CVTouchable)`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;

  & > span {
    padding-right: 1rem;
  }
`;
