import { Notification, dayjs } from "@constituentvoice/cv-elements/web";
import { faBell } from "@fortawesome/pro-solid-svg-icons/faBell";
import { faCaretUp } from "@fortawesome/pro-solid-svg-icons/faCaretUp";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { faCircle } from "@fortawesome/pro-solid-svg-icons/faCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useButton } from "@react-aria/button";
import { AriaMenuProps, useMenuTrigger } from "@react-aria/menu";
import type { MenuTriggerProps } from "@react-stately/menu";
import { useMenuTriggerState } from "@react-stately/menu";
import { CSSProperties, useRef } from "react";

import { useAnchorElementStyles } from "../../hooks/use-anchor-element-styles";

import {
  MarkAsReadButton,
  NotificationDate,
  NotificationIcon,
  NotificationItem,
  NotificationTexts,
  NotificationTitle,
  NotificationsButtonText,
  NotificationsButtonsContainer,
  NotificationsIndicator,
  NotificationsLayeredIcons,
  NotificationsPopover,
  NotificationsPopoverHeader,
  NotificationsPopoverHeaderIconContainer,
  NotificationsTriggerButton,
  NotificationsValue,
  ShowAllNotificationsLink,
} from "./Notifications.styles";

interface NotificationsProps extends AriaMenuProps<unknown>, MenuTriggerProps {
  notifications: Notification[];
  unreadCount: number;
  notificationsPageUrl: string;
  onNotificationClicked?: (id: string) => void;
  onMarkAllReadClicked?: () => Promise<void>;
  className?: string;
  style?: CSSProperties;
  setSelectedNotification: (val: Notification) => void;
}

export const Notifications = (props: Omit<NotificationsProps, "children">) => {
  const {
    notifications = [],
    unreadCount,
    notificationsPageUrl,
    onNotificationClicked,
    onMarkAllReadClicked,
    setSelectedNotification,
  } = props;
  const state = useMenuTriggerState(props);

  const ref = useRef<HTMLButtonElement>(null);
  const { menuTriggerProps } = useMenuTrigger(
    {
      isDisabled: notifications.length === 0 || state.isOpen,
    },
    {
      ...state,
      open: () => {
        setTimeout(() => {
          state.open(null);
        }, 50);
      },
      toggle: () => {
        setTimeout(() => {
          state.toggle(null);
        }, 50);
      },
    },
    ref,
  );
  const { buttonProps } = useButton(menuTriggerProps, ref);
  const popOverStyle = useAnchorElementStyles(state.isOpen, ref);

  return (
    <>
      <NotificationsTriggerButton
        key={"notifications-trigger-button"}
        id={"notifications-trigger-button"}
        {...buttonProps}
        className={props.className}
        style={props.style}
        ref={ref}
      >
        <NotificationsLayeredIcons>
          <NotificationIcon icon={faBell} />
          {unreadCount > 0 ? <NotificationsIndicator icon={faCircle} /> : null}
        </NotificationsLayeredIcons>
        <NotificationsButtonText>Notifications</NotificationsButtonText>
        {unreadCount > 0 && (
          <NotificationsValue>
            {unreadCount >= 10 ? "9+" : unreadCount}
          </NotificationsValue>
        )}
      </NotificationsTriggerButton>
      {state.isOpen && (
        <NotificationsPopover
          state={state}
          triggerRef={ref}
          placement={"bottom end"}
          offset={-50}
          style={{
            top: popOverStyle.top,
            left: popOverStyle.left,
            right: popOverStyle.right,
            bottom: popOverStyle.bottom,
          }}
        >
          <NotificationsPopoverHeader
            key={"notifications-header-button"}
            id={"notifications-header-button"}
            disabled={
              !state.isOpen || menuTriggerProps["aria-expanded"] !== true
            }
            onClick={() => {
              if (state.isOpen) state.setOpen(false);
            }}
          >
            <NotificationsLayeredIcons>
              <NotificationIcon icon={faBell} />
              {unreadCount > 0 ? (
                <NotificationsIndicator icon={faCircle} />
              ) : null}
            </NotificationsLayeredIcons>
            <span>Notifications</span>
            <NotificationsPopoverHeaderIconContainer>
              <FontAwesomeIcon icon={faCaretUp} />
            </NotificationsPopoverHeaderIconContainer>
          </NotificationsPopoverHeader>
          <ul>
            {notifications.map((notification) => {
              return (
                <li>
                  <NotificationItem
                    isUnread={!notification.isRead}
                    onClick={(e) => {
                      e.preventDefault();
                      onNotificationClicked?.(notification.id);
                      setSelectedNotification(notification);
                    }}
                  >
                    <NotificationTexts>
                      <NotificationTitle>
                        {notification.title}
                      </NotificationTitle>
                      <NotificationDate>
                        {dayjs(new Date(notification.created)).format(
                          "MM/DD/YYYY",
                        )}
                      </NotificationDate>
                    </NotificationTexts>
                  </NotificationItem>
                </li>
              );
            })}
          </ul>
          <NotificationsButtonsContainer>
            <ShowAllNotificationsLink
              href={notificationsPageUrl || "#"}
              target={"_self"}
            >
              Show all
            </ShowAllNotificationsLink>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <MarkAsReadButton onClick={onMarkAllReadClicked}>
              <NotificationsPopoverHeaderIconContainer>
                <FontAwesomeIcon icon={faCheck} />
              </NotificationsPopoverHeaderIconContainer>
              <span>Mark all read</span>
            </MarkAsReadButton>
          </NotificationsButtonsContainer>
        </NotificationsPopover>
      )}
    </>
  );
};
