import {
  DisplayErrorMessagesIfPresent,
  MoreHomeScreenUI,
  useMoreHomeV5,
} from "@constituentvoice/cv-elements/web";
import { useNavigate } from "react-router-dom";

import { cvAppSettings } from "../../../app.cv.config";
import { config } from "../../env";
import { useAppTitle } from "../../hooks/use-client-title";

export const MoreHomeScreenViewV5 = () => {
  useAppTitle("More");
  const navigate = useNavigate();
  const {
    currentEvent,
    currentUser,
    openTwitterError,
    openTwitter,
    onLogout,
    displayChooseYourOrg,
  } = useMoreHomeV5();
  const client = currentEvent?.organization;

  return (
    <>
      <DisplayErrorMessagesIfPresent
        errors={[{ val: openTwitterError, name: "Opening Twitter" }]}
      />
      <MoreHomeScreenUI
        onLogout={onLogout}
        clientServiceCode={client?.serviceCode}
        openTwitter={openTwitter}
        documentsUrl={
          currentEvent
            ? `/${client?.serviceCode}/${currentEvent?.event?.id}/event/${String(
                currentEvent?.event?.id,
              )}/documents`
            : undefined
        }
        currentUserId={currentUser ? String(currentUser.id) : undefined}
        displayOptions={cvAppSettings.more.options}
        versionCode={config.VERSION_CODE}
        onLinkClicked={(path: string) => {
          const rightPath =
            path === "/"
              ? `/`
              : path.startsWith("/") &&
                  !path.startsWith(`/${client?.serviceCode}`)
                ? `/${client?.serviceCode}/${currentEvent?.event?.id}${path}`
                : path;
          navigate(rightPath, {
            state: { editProfileDefault: true },
          });
        }}
        displayChooseYourOrg={displayChooseYourOrg}
      />
    </>
  );
};
