import {
  storeCurrentEvent,
  useAppDispatch,
  useAppSelector,
  useChooseYourGroup,
} from "@constituentvoice/cv-elements/web";
import { useEffect, useMemo } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { useUrlClientAndEventId } from "../hooks/use-url-hooks";

export const ClientListLayout = () => {
  const { previousEvents, upcomingEvents, isLoading, error } =
    useChooseYourGroup();
  const { clientId: urlClientId, eventId } = useUrlClientAndEventId();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const env = useAppSelector((state) => state.env.env);

  const matchedEvent = useMemo(() => {
    const allEvents = [...previousEvents, ...upcomingEvents];
    if (!allEvents.length) return;
    return allEvents.find(
      (event) =>
        event.organization.serviceCode === urlClientId &&
        eventId === `${event.id}`,
    );
  }, [previousEvents, upcomingEvents, urlClientId, eventId]);

  useEffect(() => {
    if (error || isLoading) return;

    if (!matchedEvent) {
      // User has no groups
      navigate("/");
      return;
    }

    void dispatch(storeCurrentEvent(matchedEvent));
  }, [matchedEvent, dispatch, navigate, error, isLoading]);

  if (!env) return <p>Loading env...</p>;

  if (error) {
    return <p>There was an error</p>;
  }

  if (isLoading) {
    return <p>Loading groups...</p>;
  }

  return <Outlet />;
};
