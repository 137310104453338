import { faCamera } from "@fortawesome/pro-regular-svg-icons/faCamera";
import { faImagePolaroid } from "@fortawesome/pro-regular-svg-icons/faImagePolaroid";

import { ENVIRONMENTS } from "../env";

export const createACCirclesArray = (
  takePicture: () => void,
  showGallery: () => void,
) => {
  return [
    {
      color: "#4a4e4d",
      icon: faCamera,
      onPress: takePicture,
    },
    {
      color: "#0e9aa7",
      icon: faImagePolaroid,
      onPress: showGallery,
    },
  ];
};

export const getCognitoId = (environment: string) => {
  if (environment === ENVIRONMENTS.STAGING) {
    return {
      USER_POOL_ID: "us-east-1_qYHUT6kVA",
      USER_POOL_CLIENT_ID: "5sm5hruv22o93l2j3vd3t8bv5u",
    };
  } else
    return {
      USER_POOL_ID: "us-east-1_1OBBcyx8y",
      USER_POOL_CLIENT_ID: "6krccgk1gvh858qcj55e7dqtlj",
    };
};
