import { useAppSelector } from "@constituentvoice/cv-elements/web";
import { useCallback } from "react";

export const useBuildClientRoute = () => {
  const currentEvent = useAppSelector(
    (state) => state.currentEvent.currentEvent,
  );
  const client = currentEvent?.organization;

  const fn = useCallback(
    (path: string) => {
      if (!path.startsWith("/")) path = `/${path}`;
      return `/${client?.serviceCode}/${currentEvent?.event?.id}${path}`;
    },
    [client?.serviceCode, currentEvent?.event?.id],
  );

  return fn;
};
