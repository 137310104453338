import {
  PasswordlessLoginUI,
  useAppSelector,
  usePasswordlessLogin,
} from "@constituentvoice/cv-elements/web";
import { Navigate, useLocation } from "react-router-dom";

import { useAppTitle } from "../../hooks/use-client-title";

export const PasswordlessLogin = () => {
  useAppTitle("Login");

  const {
    currentStep,
    timer,
    codeStatus,
    isLoading,
    remainingAttempts,
    hasTempCode,
    onFormSubmit,
    resendCode,
    startOver,
  } = usePasswordlessLogin();

  const token = useAppSelector((state) => state.user.token);
  const location = useLocation();

  // User is already logged in.  Navigate to app
  if (token) {
    return <Navigate to={location.state?.redirectTo || "../"} replace={true} />;
  }

  return (
    <PasswordlessLoginUI
      isLoading={isLoading}
      remainingAttempts={remainingAttempts}
      codeStatus={codeStatus}
      timer={timer}
      clientLogo={"/images/capitol.png"}
      currentStep={currentStep}
      onFormSubmit={onFormSubmit}
      startOver={startOver}
      onResendCodePress={() => {
        void resendCode();
      }}
      hasTempCode={hasTempCode}
    />
  );
};
