import {
  DisplayErrorMessagesIfPresent,
  SettingsScreenUI,
  useSettingsScreen,
} from "@constituentvoice/cv-elements/web";
import * as React from "react";
import { ActivityIndicator } from "react-native";
import { useNavigate } from "react-router-dom";

import { useBuildClientRoute } from "../../hooks/use-build-client-route";
import { useAppTitle } from "../../hooks/use-client-title";

export const SettingsScreenView = () => {
  const navigate = useNavigate();
  const buildRoute = useBuildClientRoute();
  useAppTitle("Settings");

  const {
    userSettings,
    isLoading,
    userSettingsError,
    changePrivacySetting,
    changePrivacySettingError,
  } = useSettingsScreen();

  if (isLoading) return <ActivityIndicator size="large" />;

  return (
    <>
      <DisplayErrorMessagesIfPresent
        errors={[
          { name: "Getting your Settings", val: userSettingsError },
          { name: "Changing your Settings", val: changePrivacySettingError },
        ]}
      />
      <SettingsScreenUI
        userSettings={userSettings}
        changePrivacySetting={changePrivacySetting}
      />
    </>
  );
};
