import {
  CVButton,
  LOCAL,
  MessagesHomeScreenUI,
  Modal,
  NotificationStatus,
  createFrontendNotification,
  dayjs,
  useAppSelector,
  useMessagesHome,
  useNetworkingParamsGQL,
} from "@constituentvoice/cv-elements/web";
import { useOverlayTriggerState } from "@react-stately/overlays";
import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import * as React from "react";

import { AlertDialog } from "../../components/AlertDialog/AlertDialog";
import { useAppTitle } from "../../hooks/use-client-title";

export const MessagesHomeScreenViewV5 = () => {
  useAppTitle("Messages");

  const {
    getNotifications,
    markAllNotificationsAsRead,
    markNotificationAsUnread,
    markNotificationAsRead,
    deleteNotification,
    notifications,
    selectedNotification,
    setSelectedNotification,
  } = useMessagesHome();

  const notificationAlertState = useOverlayTriggerState({});

  const { client } = useNetworkingParamsGQL({ enabled: true });

  const currentEvent = useAppSelector(
    (state) => state.currentEvent.currentEvent,
  );

  const currentUser = useAppSelector((state) => state.user.currentUser);
  const env = useAppSelector((state) => state.env.env);

  const { error, mutate: sendNotification } = useMutation({
    mutationFn: () =>
      client!.request(
        createFrontendNotification,
        {
          notification: {
            body: "Testing testing 123",
            title: "This is a test",
            status: NotificationStatus.Pending,
            lobbyDayId: currentEvent!.event!.id,
            clientPersonIds: [currentUser!.id],
          },
        },
        {
          "X-Api-Key": "abc123def456",
        },
      ),
  });

  useEffect(() => {
    notificationAlertState.setOpen(Boolean(selectedNotification));
  }, [selectedNotification, notificationAlertState]);

  return (
    <>
      {env?.ENVIRONMENT === LOCAL && (
        <CVButton onClick={() => sendNotification()}>Add notification</CVButton>
      )}
      <MessagesHomeScreenUI
        notifications={notifications}
        selectedNotification={selectedNotification}
        setSelectedNotification={setSelectedNotification}
        getNotifications={getNotifications}
        markAllNotificationsAsRead={markAllNotificationsAsRead}
        markNotificationAsUnread={markNotificationAsUnread}
        markNotificationAsRead={markNotificationAsRead}
        deleteNotification={deleteNotification}
      />
      {selectedNotification && notificationAlertState.isOpen ? (
        <Modal state={notificationAlertState}>
          <AlertDialog
            title={selectedNotification.title}
            date={dayjs(selectedNotification.created).format(
              "hh:mm A MMMM DD, YYYY",
            )}
            confirmLabel={"Dismiss"}
            onConfirm={() => {
              void markNotificationAsRead?.(selectedNotification.id);
              setSelectedNotification(null);
            }}
          >
            {selectedNotification.body}
          </AlertDialog>
        </Modal>
      ) : null}
    </>
  );
};
