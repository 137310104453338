import { useAppSelector } from "@constituentvoice/cv-elements/web";
import * as React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export const ValidateAuthLayout = () => {
  const token = useAppSelector((state) => state.user.token);
  const location = useLocation();

  if (!token) {
    return (
      <Navigate
        to={"../login"}
        replace={true}
        state={{ redirectTo: location.pathname }}
      />
    );
  }

  return <Outlet />;
};
