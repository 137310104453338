import { useCurrentClient } from "@constituentvoice/cv-elements/web";
import { useEffect, useLayoutEffect, useRef } from "react";

function getFaviconFromImg(img: string) {
  const url = new URL(img);
  const imgName = url.pathname.split("/").pop();
  const imgExt = imgName?.split(".").pop();

  let type!: string;

  switch (imgExt) {
    case "svg":
      type = "image/svg+xml";
      break;
    case "png":
      type = "image/png";
      break;
    case "jpg":
    case "jpeg":
      type = "image/jpeg";
      break;
    case "gif":
      type = "image/gif";
      break;
    default:
      type = "image/x-icon";
  }

  return { type, img };
}

function getFaviconEl() {
  const favicon = document.querySelector(
    "link[rel*='icon']",
  ) as HTMLLinkElement;

  return favicon;
}

const originalFavicon = `${window.location.origin}/favicon.ico`;

export const useClientFavicon = () => {
  const client = useCurrentClient();

  useEffect(() => {
    const favicon = getFaviconEl();

    if (!client) {
      const img = originalFavicon ?? "";
      const { type } = getFaviconFromImg(img);
      favicon.href = img;
      favicon.type = type;
      return;
    }

    const img = client.logoUrl || originalFavicon || "";
    const { type } = getFaviconFromImg(img);
    favicon.href = img;
    favicon.type = type;
  }, [client]);
};
