import {
  getEventSurveyV5,
  useAppSelector,
  useNetworkingParamsV5,
} from "@constituentvoice/cv-elements/web";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { TopNav } from "../../components/TopNav/TopNav";

export const TopNavLayout = () => {
  const currentEvent = useAppSelector(
    (state) => state.currentEvent.currentEvent,
  );
  const networkingProps = useNetworkingParamsV5();
  const { data: survey, error: surveyError } = useQuery({
    queryKey: ["survey", currentEvent?.event?.id],
    queryFn: async ({ signal }) => {
      return await getEventSurveyV5({
        ...networkingProps,
        signal,
        eventId: currentEvent?.event?.id || "",
      });
    },
    enabled: !!currentEvent?.event?.id,
  });

  useEffect(() => {
    if (!surveyError) return;
    console.error({ surveyError });
  }, [surveyError]);

  return (
    <TopNav
      showEventFeedback={
        !!survey?.survey && Boolean(survey?.survey?.questions?.length)
      }
    >
      <Outlet />
    </TopNav>
  );
};
